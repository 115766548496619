import React, { useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  useTheme,
  Paper,
  Typography,
  Stack,
  Chip,
  TextField,
} from '@mui/material';
import { BlockNoteView } from '@blocknote/mantine';
import { BlockNoteEditor } from '@blocknote/core';
import { format } from 'date-fns';
import '@blocknote/core/fonts/inter.css';
import '@blocknote/mantine/style.css';
import Breadcrumbs from '../Navigation/Breadcrumbs';
import { useWorkspaceStore, DEFAULT_BLOCK } from '../../store/useWorkspaceStore';
import debounce from 'lodash/debounce';

const Workspace = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const { workspaceId } = useParams();

  // Debug logging
  console.log('Workspace Component - ID:', workspaceId);

  const workspaces = useWorkspaceStore((state) => state.workspaces);
  const updateWorkspace = useWorkspaceStore((state) => state.updateWorkspace);
  const currentWorkspace = workspaces.find((w) => w.id === workspaceId);

  // Add local state for the title
  const [localTitle, setLocalTitle] = React.useState(currentWorkspace?.title || '');

  // Update local title when workspace changes
  React.useEffect(() => {
    setLocalTitle(currentWorkspace?.title || '');
  }, [currentWorkspace?.title]);

  // Get initial content from workspace
  const initialContent = useMemo(() => {
    if (!currentWorkspace?.content) return undefined;
    try {
      const parsedContent = JSON.parse(currentWorkspace.content);
      // Ensure we always have at least one block
      return parsedContent.length > 0 ? parsedContent : DEFAULT_BLOCK;
    } catch (error) {
      console.error('Error parsing workspace content:', error);
      return DEFAULT_BLOCK;
    }
  }, [currentWorkspace?.content]);

  // Track if slash menu is open
  const [isSlashMenuOpen, setIsSlashMenuOpen] = React.useState(false);

  // Initialize editor once
  const editor = useMemo(() => {
    if (!initialContent) return null;
    return BlockNoteEditor.create({
      initialContent,
      domAttributes: {
        class: 'editor-wrapper'
      }
    });
  }, []); // Empty dependency array as we handle content updates differently

  // Single save mechanism
  const saveContent = useMemo(
    () =>
      debounce((blocks) => {
        if (!blocks || !workspaceId) return;
        updateWorkspace(workspaceId, {
          content: JSON.stringify(blocks)
        });
      }, 1000),
    [workspaceId, updateWorkspace]
  );

  // Cleanup
  useEffect(() => {
    return () => {
      saveContent.cancel();
      if (editor) {
        const finalContent = editor.topLevelBlocks;
        if (finalContent) {
          updateWorkspace(workspaceId, {
            content: JSON.stringify(finalContent)
          });
        }
        editor.destroy?.();
      }
    };
  }, [editor, saveContent, updateWorkspace, workspaceId]);

  // Create the debounced update function
  const debouncedTitleUpdate = useMemo(
    () =>
      debounce((newTitle) => {
        updateWorkspace(workspaceId, {
          title: newTitle || 'Untitled',
        });
      }, 500),
    [workspaceId, updateWorkspace]
  );

  // Handle title changes
  const handleTitleChange = (event) => {
    const newTitle = event.target.value;
    setLocalTitle(newTitle); // Update local state with exactly what user typed
    debouncedTitleUpdate(newTitle); // Let the store handle the 'Untitled' fallback
  };

  // Cleanup
  useEffect(() => {
    return () => {
      debouncedTitleUpdate.cancel();
    };
  }, [debouncedTitleUpdate]);

  const breadcrumbs = [
    { label: 'Home', path: '/app' },
    { label: 'Workspaces', path: '/app/workspaces' },
    {
      label: currentWorkspace?.title || 'Untitled',
      path: `/app/workspace/${workspaceId}`,
    },
  ];

  if (!currentWorkspace) {
    return <div>Workspace not found</div>;
  }

  return (
    <Stack spacing={2} sx={{ height: 'calc(100vh - 88px)' }}>
      {/* Metadata Card with integrated breadcrumbs */}
      <Paper
        sx={{
          p: 3,
          backgroundColor: 'background.paper',
        }}
      >
        <Stack spacing={1}>
          {/* Breadcrumbs */}
          <Box sx={{ mb: 1 }}>
            <Breadcrumbs
              items={breadcrumbs}
              sx={{
                '& .MuiBreadcrumbs-ol': {
                  fontSize: '0.75rem',
                },
                '& .MuiBreadcrumbs-separator': {
                  margin: '0 4px',
                },
              }}
            />
          </Box>

          {/* Title */}
          <TextField
            variant="standard"
            placeholder="Untitled"
            value={localTitle}
            onChange={handleTitleChange}
            fullWidth
            sx={{
              '& .MuiInputBase-input': {
                fontSize: '1.5rem',
                fontWeight: 500,
                padding: '4px 0',
              },
              '& .MuiInput-underline:before': {
                borderBottomColor: 'transparent',
              },
              '& .MuiInput-underline:hover:before': {
                borderBottomColor: 'divider',
              },
            }}
          />

          {/* Metadata Row */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              color: 'text.secondary',
            }}
          >
            <Typography variant="body2">
              Created{' '}
              {format(
                new Date(currentWorkspace?.createdAt || new Date()),
                'MMM d, yyyy',
              )}
            </Typography>
            <Typography variant="body2">•</Typography>
            <Typography variant="body2">By John Doe</Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Chip
              label="Draft"
              size="small"
              color="default"
              variant="outlined"
            />
          </Box>
        </Stack>
      </Paper>

      {/* Editor Card */}
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: 'background.paper',
          borderRadius: 1,
          p: 3,
          '& .bn-container': {
            backgroundColor: 'background.paper',
          },
          '& .bn-editor': {
            padding: 0,
            backgroundColor: 'background.paper',
          },
          '& .bn-editor-content': {
            color: 'text.primary',
            backgroundColor: 'background.paper',
          },
          '& .bn-toolbar': {
            backgroundColor: 'background.paper',
          },
        }}
      >
        <BlockNoteView
          editor={editor}
          theme={{
            colors: {
              editor: {
                text: theme.palette.text.primary,
                background: theme.palette.background.paper,
              }
            }
          }}
          onChange={() => {
            if (!isSlashMenuOpen && editor) {
              saveContent(editor.topLevelBlocks);
            }
          }}
          onSlashMenuOpen={() => setIsSlashMenuOpen(true)}
          onSlashMenuClose={() => setIsSlashMenuOpen(false)}
        />
      </Box>
    </Stack>
  );
};

export default Workspace;
