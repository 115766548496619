import React, { useState } from 'react';
import {
  Paper,
  Box,
  Typography,
  IconButton,
  TextField,
  Tooltip,
  Chip,
  Collapse,
  Divider,
} from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TaskDetails from './TaskDetails';

const Task = ({ task, onEdit, onDelete, provided, snapshot }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [editedTitle, setEditedTitle] = useState(task.title);

  const handleComplete = () => {
    onEdit(task.id, {
      ...task,
      status: task.status === 'completed' ? 'pending' : 'completed',
    });
  };

  const handleSave = () => {
    if (editedTitle.trim()) {
      onEdit(task.id, { ...task, title: editedTitle.trim() });
      setIsEditing(false);
    }
  };

  return (
    <Box
      ref={provided.innerRef}
      {...provided.draggableProps}
      sx={{ 
        mb: 2,
        transition: 'background-color 0.2s ease',
        backgroundColor: snapshot?.isDragging ? 'lightblue' : 'background.paper',
        borderRadius: 2,
        boxShadow: snapshot?.isDragging ? 3 : 1,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          p: 2,
          backgroundColor: 'background.paper',
          borderRadius: 2,
          transition: 'all 0.2s ease-in-out',
          '&:hover': {
            boxShadow: 1,
          },
        }}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <Box {...provided.dragHandleProps} sx={{ cursor: 'grab', touchAction: 'none' }}>
            <DragIndicatorIcon color="action" />
          </Box>

          <IconButton
            onClick={handleComplete}
            color={task.status === 'completed' ? 'primary' : 'default'}
            size="small"
          >
            {task.status === 'completed' ? (
              <CheckCircleIcon />
            ) : (
              <RadioButtonUncheckedIcon />
            )}
          </IconButton>

          <Box
            flexGrow={1}
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{ cursor: 'pointer' }}
          >
            {isEditing ? (
              <TextField
                fullWidth
                variant="standard"
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
                onBlur={handleSave}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleSave();
                  if (e.key === 'Escape') {
                    setIsEditing(false);
                    setEditedTitle(task.title);
                  }
                }}
                onClick={(e) => e.stopPropagation()}
                autoFocus
                sx={{ mt: 0.5 }}
              />
            ) : (
              <Typography variant="subtitle1" sx={{ py: 1 }}>{task.title}</Typography>
            )}
          </Box>

          <Box display="flex" gap={1} alignItems="center">
            {task.priority === 'high' && (
              <Chip
                label="High Priority"
                size="small"
                color="error"
                variant="outlined"
              />
            )}
            <Tooltip title="Edit">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditing(true);
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(task.id);
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={isExpanded ? 'Collapse' : 'Expand'}>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsExpanded(!isExpanded);
                }}
                sx={{
                  transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.2s',
                }}
              >
                <ExpandMoreIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Collapse in={isExpanded}>
          <Divider sx={{ my: 2 }} />
          <TaskDetails 
            task={task} 
            onEdit={onEdit}
          />
        </Collapse>
      </Paper>
    </Box>
  );
};

export default Task;
