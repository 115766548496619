import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

// Default initial block for new workspaces
export const DEFAULT_BLOCK = [
  {
    id: '1',
    type: 'paragraph',
    props: {
      textColor: 'default',
      backgroundColor: 'default',
      textAlignment: 'left',
    },
    content: [
      {
        type: 'text',
        text: '',
        styles: {},
      },
    ],
    children: [],
  },
];

export const useWorkspaceStore = create(
  persist(
    (set, get) => ({
      workspaces: [],

      createWorkspace: (data) => {
        const newWorkspace = {
          id: Date.now().toString(),
          title: data.title || 'Untitled',
          content: JSON.stringify(DEFAULT_BLOCK), // This is correct
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
        console.log('Creating workspace:', newWorkspace);

        set((state) => ({
          workspaces: [...state.workspaces, newWorkspace],
        }));

        return newWorkspace;
      },

      updateWorkspace: (id, updates) => {
        // Only set title to 'Untitled' when storing, not during editing
        if (updates.title !== undefined) {
          updates.title = updates.title.trim() || 'Untitled';
        }

        // Add validation for content
        if (!updates.content || updates.content === '[]' || updates.content === '') {
          updates.content = JSON.stringify(DEFAULT_BLOCK);
        }

        // Validate content is proper JSON
        try {
          JSON.parse(updates.content);
        } catch (e) {
          updates.content = JSON.stringify(DEFAULT_BLOCK);
        }

        set((state) => ({
          workspaces: state.workspaces.map((workspace) =>
            workspace.id === id
              ? {
                  ...workspace,
                  ...updates,
                  updatedAt: new Date().toISOString(),
                }
              : workspace
          ),
        }));
      },

      deleteWorkspace: (id) =>
        set((state) => ({
          workspaces: state.workspaces.filter(
            (workspace) => workspace.id !== id,
          ),
        })),
    }),
    {
      name: 'workspaces-storage',
      storage: createJSONStorage(() => localStorage),
      version: 1,
    },
  ),
);
