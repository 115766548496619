import React from 'react';
import { Box, Typography } from '@mui/material';

const WorklogCalendarView = () => {
  return (
    <Box sx={{ p: 3, textAlign: 'center' }}>
      <Typography variant="h6" color="text.secondary">
        Calendar View (Coming Soon)
      </Typography>
      <Typography variant="body2" color="text.secondary">
        You'll be able to view your work logs in a calendar format
      </Typography>
    </Box>
  );
};

export default WorklogCalendarView; 