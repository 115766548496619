import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Slider,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';

const TimelineConfig = ({ open, onClose, config, onSave }) => {
  const [localConfig, setLocalConfig] = React.useState(config);

  // Update local config when props change
  useEffect(() => {
    setLocalConfig(config);
  }, [config]);

  const handleSave = () => {
    onSave(localConfig);
    onClose();
  };

  const handleReset = () => {
    const defaultConfig = {
      startHour: 0,
      endHour: 24,
      workingHoursStart: '09:00',
      workingHoursEnd: '17:00',
      showWorkingHours: true,
    };
    setLocalConfig(defaultConfig);
    onSave(defaultConfig);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Timeline Settings</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
          {/* Hour Range Slider */}
          <Box>
            <Typography gutterBottom>Display Hours Range</Typography>
            <Slider
              value={[localConfig.startHour, localConfig.endHour]}
              onChange={(_, newValue) => 
                setLocalConfig(prev => ({
                  ...prev,
                  startHour: newValue[0],
                  endHour: newValue[1]
                }))
              }
              valueLabelDisplay="auto"
              min={0}
              max={24}
              marks
              step={1}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="caption">
                Start: {localConfig.startHour}:00
              </Typography>
              <Typography variant="caption">
                End: {localConfig.endHour}:00
              </Typography>
            </Box>
          </Box>

          {/* Working Hours */}
          <Box>
            <Typography gutterBottom>Working Hours</Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <TextField
                label="Start"
                type="time"
                value={localConfig.workingHoursStart}
                onChange={(e) => 
                  setLocalConfig(prev => ({
                    ...prev,
                    workingHoursStart: e.target.value
                  }))
                }
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="End"
                type="time"
                value={localConfig.workingHoursEnd}
                onChange={(e) => 
                  setLocalConfig(prev => ({
                    ...prev,
                    workingHoursEnd: e.target.value
                  }))
                }
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Box>

          {/* Additional Settings */}
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={localConfig.showWorkingHours}
                  onChange={(e) => 
                    setLocalConfig(prev => ({
                      ...prev,
                      showWorkingHours: e.target.checked
                    }))
                  }
                />
              }
              label="Highlight Working Hours"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset} color="error">
          Reset to Default
        </Button>
        <Box sx={{ flex: 1 }} />
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained">
          Save Settings
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimelineConfig; 