// src/pages/LandingPage.jsx
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Typography,
  Button,
  Container,
  Box,
  Grid,
  Card,
  CardContent,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { CheckCircleOutline } from '@mui/icons-material';
import { ColorModeContext } from '../App';
import heroSvg from '../assets/hero-1.svg';

const LandingPage = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const colors = theme.palette.customColors;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      style={{ backgroundColor: colors.background, color: colors.textPrimary }}
    >
      {/* Theme Toggle Button */}
      <Box sx={{ position: 'fixed', top: 16, right: 16, zIndex: 1000 }}>
        <IconButton
          onClick={colorMode.toggleColorMode}
          sx={{ color: colors.textPrimary }}
        >
          {theme.palette.mode === 'dark' ? (
            <Brightness7Icon />
          ) : (
            <Brightness4Icon />
          )}
        </IconButton>
      </Box>

      {/* Hero Section */}
      <Container maxWidth="lg" sx={{ pt: 12, pb: 6 }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: '2.5rem', md: '3.5rem' },
                fontWeight: 700,
                mb: 2,
              }}
            >
              Your AI Productivity Assistant
            </Typography>
            <Typography
              variant="h5"
              color="textSecondary"
              sx={{ mb: 4, lineHeight: 1.5 }}
            >
              Experience seamless task management, intelligent meeting
              assistance, and focused work sessions - all powered by AI to help
              you achieve more with less effort.
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
              <Button
                variant="contained"
                size="large"
                component={Link}
                to="/app/signup"
                sx={{
                  backgroundColor: colors.buttonPrimaryBackground,
                  color: colors.buttonPrimaryText,
                  py: 1.5,
                  px: 4,
                  '&:hover': {
                    backgroundColor: colors.buttonPrimaryHover,
                  },
                }}
              >
                Get Started
              </Button>
              <Button
                variant="outlined"
                size="large"
                component={Link}
                to="/app/signin"
                sx={{
                  backgroundColor: colors.buttonSecondaryBackground,
                  color: colors.buttonSecondaryText,
                  borderColor: colors.buttonSecondaryBorder,
                  py: 1.5,
                  px: 4,
                  '&:hover': {
                    backgroundColor: colors.buttonSecondaryHoverBackground,
                  },
                }}
              >
                Sign In
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ width: '85%', ml: '50px' }}>
              <img
                src={heroSvg}
                alt="Focus Hero Dashboard Preview"
                style={{
                  width: '100%',
                  height: 'auto',
                  filter: theme.palette.mode === 'dark' ? 'invert(1)' : 'none'
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* AI Features Section */}
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Typography variant="h2" align="center" sx={{ mb: 6 }}>
          AI-Powered Features
        </Typography>
        <Grid container spacing={4}>
          {[
            {
              title: 'Intelligent Task Prioritization',
              description:
                'Our AI analyzes your tasks based on complexity, impact, and deadlines to suggest the optimal work order.',
              features: [
                'Smart complexity scoring',
                'Impact assessment',
                'Deadline optimization',
                'Automatic task categorization',
              ],
            },
            {
              title: 'Meeting Assistant',
              description:
                'Let AI handle your meeting workflow - from preparation to follow-up actions.',
              features: [
                'Automated meeting summaries',
                'Action item extraction',
                'Smart scheduling suggestions',
                'Meeting effectiveness analysis',
              ],
            },
            {
              title: 'Focus Session Optimization',
              description:
                'AI-optimized work intervals that adapt to your productivity patterns.',
              features: [
                'Personalized session lengths',
                'Smart break scheduling',
                'Productivity pattern analysis',
                'Focus recommendations',
              ],
            },
            {
              title: 'Worklog Intelligence',
              description:
                'Automated work tracking with AI insights to improve your productivity.',
              features: [
                'Activity pattern recognition',
                'Productivity insights',
                'Time allocation analysis',
                'Progress tracking',
              ],
            },
          ].map((feature) => (
            <Grid item xs={12} md={6} key={feature.title}>
              <Card sx={{ height: '100%', p: 3 }}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {feature.title}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {feature.description}
                  </Typography>
                  <List>
                    {feature.features.map((item) => (
                      <ListItem key={item} sx={{ py: 0.5 }}>
                        <ListItemIcon>
                          <CheckCircleOutline sx={{ color: colors.accent }} />
                        </ListItemIcon>
                        <ListItemText primary={item} />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* CTA Section */}
      <Box sx={{ bgcolor: colors.cardBackground, py: 8 }}>
        <Container maxWidth="md">
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h3" gutterBottom>
              Your Personal AI Assistant Awaits
            </Typography>
            <Typography variant="h6" color="textSecondary" paragraph>
              Experience the future of productivity with AI-powered task
              management, meetings, and focus sessions.
            </Typography>
            <Button
              variant="contained"
              size="large"
              component={Link}
              to="/app/signup"
              sx={{
                mt: 4,
                backgroundColor: colors.buttonPrimaryBackground,
                color: colors.buttonPrimaryText,
                py: 2,
                px: 6,
                '&:hover': {
                  backgroundColor: colors.buttonPrimaryHover,
                },
              }}
            >
              Start Now
            </Button>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default LandingPage;
