import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Breadcrumbs = ({ items, sx }) => {
  return (
    <MuiBreadcrumbs 
      separator={<NavigateNextIcon fontSize="small" sx={{ fontSize: '0.75rem' }} />}
      aria-label="breadcrumb"
      sx={sx}
    >
      {items.map((item, index) => {
        const isLast = index === items.length - 1;
        
        if (isLast) {
          return (
            <Typography 
              key={item.path} 
              color="text.secondary"
              variant="caption"
            >
              {item.label}
            </Typography>
          );
        }

        return (
          <Link
            key={item.path}
            component={RouterLink}
            to={item.path}
            underline="hover"
            color="inherit"
            variant="caption"
          >
            {item.label}
          </Link>
        );
      })}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs; 