import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  TextField, 
  Button, 
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Chip,
} from '@mui/material';
import { Add as AddIcon, LightbulbOutlined } from '@mui/icons-material';
import { format } from 'date-fns';
import { useWorklogStore } from '../store/useWorklogStore';
import WorklogViewSelector from '../components/Worklog/WorklogViewSelector';
import WorklogListView from '../components/Worklog/WorklogListView';
import WorklogTimelineView from '../components/Worklog/WorklogTimelineView';
import WorklogCalendarView from '../components/Worklog/WorklogCalendarView';
import WorklogTableView from '../components/Worklog/WorklogTableView';

const WorklogPage = () => {
  const [currentView, setCurrentView] = useState('list');
  const [newEntry, setNewEntry] = useState({
    content: '',
    category: 'feature',
    tags: [],
    date: new Date().toISOString(),
  });
  const [newTag, setNewTag] = useState('');

  const entries = useWorklogStore((state) => state.entries);
  const addEntry = useWorklogStore((state) => state.addEntry);
  const deleteEntry = useWorklogStore((state) => state.deleteEntry);

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setCurrentView(newView);
    }
  };

  const handleAddTag = (e) => {
    if (e.key === 'Enter' && newTag.trim()) {
      e.preventDefault();
      setNewEntry({
        ...newEntry,
        tags: [...newEntry.tags, newTag.trim()],
      });
      setNewTag('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setNewEntry({
      ...newEntry,
      tags: newEntry.tags.filter(tag => tag !== tagToRemove),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newEntry.content.trim()) return;
    
    addEntry(newEntry);
    setNewEntry({
      content: '',
      category: 'feature',
      tags: [],
      date: new Date().toISOString(),
    });
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.ctrlKey && e.key === 'n') {
        e.preventDefault();
        // Focus the content input
        document.querySelector('[name="content"]')?.focus();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  return (
    <Box sx={{ maxWidth: '100%', px: { xs: 2, sm: 3 }, py: { xs: 2, sm: 4 } }}>
      <Box sx={{ maxWidth: 1400, mx: 'auto' }}>
        {/* Header Section */}
        <Paper 
          elevation={0} 
          sx={{ 
            p: 3, 
            mb: 3,
            backgroundColor: 'background.paper',
            borderRadius: 1,
          }}
        >
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mb: 3,
              gap: 2,
              flexWrap: 'wrap'
            }}
          >
            <Typography variant="h5" fontWeight={600}>
              Work Log
            </Typography>
            <WorklogViewSelector
              currentView={currentView}
              onViewChange={handleViewChange}
            />
          </Box>

          {/* Entry Form */}
          <form onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <FormControl sx={{ width: 200 }}>
                  <TextField
                    type="date"
                    label="Date"
                    value={format(new Date(newEntry.date), "yyyy-MM-dd")}
                    onChange={(e) => setNewEntry({ 
                      ...newEntry, 
                      date: new Date(e.target.value).toISOString() 
                    })}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
                
                <FormControl sx={{ width: 200 }}>
                  <InputLabel>Category</InputLabel>
                  <Select
                    value={newEntry.category}
                    label="Category"
                    onChange={(e) => setNewEntry({ ...newEntry, category: e.target.value })}
                  >
                    {['feature', 'bug', 'maintenance', 'meeting', 'research', 'other'].map((cat) => (
                      <MenuItem key={cat} value={cat}>
                        {cat.charAt(0).toUpperCase() + cat.slice(1)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                
                <TextField
                  label="Add Tags"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  onKeyDown={handleAddTag}
                  placeholder="Press Enter to add"
                  fullWidth
                />
              </Box>

              <TextField
                multiline
                rows={3}
                placeholder="What did you work on?"
                value={newEntry.content}
                onChange={(e) => setNewEntry({ ...newEntry, content: e.target.value })}
                fullWidth
              />

              {newEntry.tags.length > 0 && (
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                  {newEntry.tags.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag}
                      onDelete={() => handleRemoveTag(tag)}
                      size="small"
                      variant="outlined"
                    />
                  ))}
                </Box>
              )}

              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  type="submit"
                  disabled={!newEntry.content.trim()}
                >
                  Add Entry
                </Button>
              </Box>
            </Stack>
          </form>
        </Paper>

        {/* Views */}
        {currentView === 'list' && (
          <WorklogListView entries={entries} onDelete={deleteEntry} />
        )}
        {currentView === 'timeline' && (
          <WorklogTimelineView entries={entries} onDelete={deleteEntry} />
        )}
        {currentView === 'calendar' && (
          <WorklogCalendarView entries={entries} onDelete={deleteEntry} />
        )}
        {currentView === 'table' && (
          <WorklogTableView entries={entries} onDelete={deleteEntry} />
        )}
      </Box>
    </Box>
  );
};

export default WorklogPage; 