import React from 'react';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  IconButton,
  Tooltip,
  Divider,
} from '@mui/material';
import {
  Description as DescriptionIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { useWorkspaceStore } from '../store/useWorkspaceStore';

const WorkspacesPage = () => {
  const navigate = useNavigate();
  const workspaces = useWorkspaceStore(state => state.workspaces);
  const createWorkspace = useWorkspaceStore(state => state.createWorkspace);

  const handleCreateWorkspace = () => {
    try {
      const newWorkspace = createWorkspace({
        title: 'Untitled',
      });
      if (newWorkspace?.id) {
        navigate(`/app/workspace/${newWorkspace.id}`);
      }
    } catch (error) {
      console.error('Error creating workspace:', error);
    }
  };

  return (
    <Box sx={{ maxWidth: '800px', margin: '0 auto', p: 2 }}>
      {/* Header */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3 
      }}>
        <Typography variant="h5" fontWeight={500}>
          Workspaces
        </Typography>
        <Tooltip title="New Workspace">
          <IconButton 
            onClick={handleCreateWorkspace}
            sx={{ 
              backgroundColor: 'background.paper',
              border: '1px solid',
              borderColor: 'divider',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
            }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Box>

      {/* Workspaces List */}
      <Paper 
        elevation={0} 
        sx={{ 
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
        }}
      >
        <List sx={{ p: 0 }}>
          {workspaces.map((workspace, index) => (
            <React.Fragment key={workspace.id}>
              {index > 0 && <Divider />}
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => navigate(`/app/workspace/${workspace.id}`)}
                  sx={{
                    py: 2,
                    px: 3,
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    },
                  }}
                >
                  <ListItemIcon>
                    <DescriptionIcon 
                      sx={{ 
                        color: 'text.secondary',
                        fontSize: 20,
                      }} 
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body1" fontWeight={500}>
                        {workspace.title || 'Untitled'}
                      </Typography>
                    }
                    secondary={
                      <Box 
                        component="span" 
                        sx={{ 
                          display: 'flex', 
                          gap: 2,
                          color: 'text.secondary',
                          fontSize: '0.875rem',
                        }}
                      >
                        <span>John Doe</span>
                        <span>•</span>
                        <span>
                          Edited {format(new Date(workspace.updatedAt), 'MMM d, yyyy')}
                        </span>
                      </Box>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          ))}
          {workspaces.length === 0 && (
            <ListItem sx={{ py: 4 }}>
              <ListItemText
                sx={{ textAlign: 'center' }}
                primary={
                  <Typography color="text.secondary">
                    No workspaces yet. Create your first one!
                  </Typography>
                }
              />
            </ListItem>
          )}
        </List>
      </Paper>
    </Box>
  );
};

export default WorkspacesPage; 