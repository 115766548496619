import React from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import {
  ViewList,
  Timeline,
  CalendarMonth,
  TableChart,
} from '@mui/icons-material';

const WorklogViewSelector = ({ currentView, onViewChange }) => {
  return (
    <ToggleButtonGroup
      value={currentView}
      exclusive
      onChange={onViewChange}
      aria-label="view selector"
      size="small"
    >
      <ToggleButton value="list" aria-label="list view">
        <ViewList />
      </ToggleButton>
      <ToggleButton value="table" aria-label="table view">
        <TableChart />
      </ToggleButton>
      <ToggleButton value="timeline" aria-label="timeline view">
        <Timeline />
      </ToggleButton>
      <ToggleButton value="calendar" aria-label="calendar view">
        <CalendarMonth />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default WorklogViewSelector; 