import React from 'react';
import { Box, Paper, Typography, Chip, IconButton, Stack, Tooltip } from '@mui/material';
import { 
  Delete as DeleteIcon,
  Event as EventIcon,
  AccessTime as TimeIcon 
} from '@mui/icons-material';
import { 
  Timeline, 
  TimelineItem, 
  TimelineSeparator, 
  TimelineContent, 
  TimelineDot, 
  TimelineConnector,
  TimelineOppositeContent 
} from '@mui/lab';
import { format } from 'date-fns';

const WorklogTimelineView = ({ entries, onDelete }) => {
  const groupedEntries = entries.reduce((groups, entry) => {
    const date = format(new Date(entry.date), 'yyyy-MM-dd');
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(entry);
    return groups;
  }, {});

  return (
    <Timeline position="alternate">
      {Object.entries(groupedEntries).map(([date, dayEntries]) => (
        <TimelineItem key={date}>
          <TimelineOppositeContent>
            <Typography variant="h6" component="div">
              {format(new Date(date), 'EEEE, MMMM d, yyyy')}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {dayEntries.length} entries
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color="primary">
              <EventIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Stack spacing={2} sx={{ mt: 2 }}>
              {dayEntries.map((entry, index) => (
                <Paper 
                  key={entry.id}
                  elevation={0}
                  sx={{ 
                    p: 2, 
                    backgroundColor: 'background.paper',
                    position: 'relative',
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    }
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                    <TimeIcon fontSize="small" color="action" />
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="body2">{entry.content}</Typography>
                      <Typography variant="caption" color="text.secondary">
                        {format(new Date(entry.date), 'p')}
                      </Typography>
                    </Box>
                    <Tooltip title="Delete entry">
                      <IconButton 
                        size="small" 
                        onClick={() => onDelete(entry.id)}
                        sx={{ 
                          opacity: 0,
                          transition: 'opacity 0.2s',
                          '.MuiPaper-root:hover &': {
                            opacity: 1
                          }
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 1, ml: 4 }}>
                    <Chip 
                      label={entry.category} 
                      size="small" 
                      color="primary" 
                      variant="outlined" 
                    />
                    {entry.tags.map((tag, tagIndex) => (
                      <Chip 
                        key={tagIndex} 
                        label={tag} 
                        size="small" 
                        variant="outlined" 
                      />
                    ))}
                  </Box>
                </Paper>
              ))}
            </Stack>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default WorklogTimelineView; 