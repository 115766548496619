import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Tabs,
  Tab,
  Typography,
  Chip,
  IconButton,
  Checkbox,
  Tooltip,
  Button,
  Slider,
} from '@mui/material';
import {
  Edit as EditIcon,
  ListAlt as ListAltIcon,
  Assessment as AssessmentIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Autorenew as AutorenewIcon,
} from '@mui/icons-material';
import { analyzeTask, calculatePriorityScore } from '../../utils/taskPriority';
import {
  TASK_CATEGORIES,
  TASK_TYPES,
  CATEGORY_ICONS,
  TYPE_ICONS,
} from '../../utils/taskConstants';

const TaskDetails = ({ task, onEdit }) => {
  console.log('TaskDetails props:', { task, onEdit });

  // Add local state for each slider
  const [urgencyScore, setUrgencyScore] = useState(task.scores?.urgency || 0);
  const [complexityScore, setComplexityScore] = useState(task.scores?.complexity || 0);
  const [clarityScore, setClarityScore] = useState(task.scores?.clarity || 0);
  const [impactScore, setImpactScore] = useState(task.scores?.impact || 0);

  // Update local state when task changes
  useEffect(() => {
    setUrgencyScore(task.scores?.urgency || 0);
    setComplexityScore(task.scores?.complexity || 0);
    setClarityScore(task.scores?.clarity || 0);
    setImpactScore(task.scores?.impact || 0);
  }, [task.scores]);

  const handleScoreChange = (scoreType, newValue, localSetterFn) => {
    // Update local state immediately for visual feedback
    localSetterFn(newValue);
    
    // Debounce the actual task update
    onEdit(task.id, {
      ...task,
      scores: {
        ...(task.scores || {}),
        [scoreType]: newValue,
      },
    });
  };

  const [selectedCategory, setSelectedCategory] = useState(task.category || '');
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        variant="fullWidth"
        sx={{
          minHeight: 36,
          '& .MuiTabs-indicator': {
            height: 2,
          },
          '& .MuiTab-root': {
            minHeight: 36,
            padding: '6px 12px',
            textTransform: 'none',
            fontSize: '0.875rem',
          }
        }}
      >
        <Tab 
          label="Details" 
          sx={{ minHeight: 36 }}
        />
        <Tab 
          label="Checklist & Links" 
          sx={{ minHeight: 36 }}
        />
        <Tab 
          label="Priority" 
          sx={{ minHeight: 36 }}
        />
      </Tabs>

      {/* Details Tab */}
      <TabPanel value={activeTab} index={0}>
        <Grid container spacing={2}>
          {/* Category Selection */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size="small">
              <InputLabel>Category</InputLabel>
              <Select
                value={selectedCategory}
                label="Category"
                onChange={(e) => {
                  setSelectedCategory(e.target.value);
                  onEdit(task.id, {
                    ...task,
                    category: e.target.value,
                    type: '', // Reset type when category changes
                  });
                }}
              >
                {TASK_CATEGORIES.map((category) => (
                  <MenuItem key={category.value} value={category.value}>
                    <Box display="flex" alignItems="center" gap={1}>
                      {CATEGORY_ICONS[category.value] &&
                        React.createElement(CATEGORY_ICONS[category.value])}
                      <span>{category.label}</span>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Task Type Selection */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size="small" disabled={!selectedCategory}>
              <InputLabel>Type</InputLabel>
              <Select
                value={task.type || ''}
                label="Type"
                onChange={(e) =>
                  onEdit(task.id, { ...task, type: e.target.value })
                }
              >
                {TASK_TYPES[selectedCategory]?.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    <Box display="flex" alignItems="center" gap={1}>
                      {TYPE_ICONS[type.value] &&
                        React.createElement(TYPE_ICONS[type.value])}
                      <span>{type.label}</span>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Description Section */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Description"
              value={task.description || ''}
              onChange={(e) =>
                onEdit(task.id, { ...task, description: e.target.value })
              }
              placeholder="Describe the task in detail. Include any relevant context, requirements, or notes."
              size="small"
            />
          </Grid>

          {/* Status */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size="small">
              <InputLabel>Status</InputLabel>
              <Select
                value={task.status || 'backlog'}
                label="Status"
                onChange={(e) =>
                  onEdit(task.id, { ...task, status: e.target.value })
                }
              >
                <MenuItem value="backlog">Backlog</MenuItem>
                <MenuItem value="todo">To Do</MenuItem>
                <MenuItem value="in_progress">In Progress</MenuItem>
                <MenuItem value="in_review">In Review</MenuItem>
                <MenuItem value="blocked">Blocked</MenuItem>
                <MenuItem value="done">Done</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Priority Level */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size="small">
              <InputLabel>Priority</InputLabel>
              <Select
                value={task.priority || 'medium'}
                label="Priority"
                onChange={(e) =>
                  onEdit(task.id, { ...task, priority: e.target.value })
                }
              >
                <MenuItem value="critical">P0 - Critical</MenuItem>
                <MenuItem value="high">P1 - High</MenuItem>
                <MenuItem value="medium">P2 - Medium</MenuItem>
                <MenuItem value="low">P3 - Low</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Due Date */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              label="Due Date"
              type="datetime-local"
              value={task.dueDate || ''}
              onChange={(e) =>
                onEdit(task.id, { ...task, dueDate: e.target.value })
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          {/* Time Estimate */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size="small">
              <InputLabel>Time Estimate</InputLabel>
              <Select
                value={task.timeEstimate || ''}
                label="Time Estimate"
                onChange={(e) =>
                  onEdit(task.id, { ...task, timeEstimate: e.target.value })
                }
              >
                <MenuItem value="quickWin">Quick Win (under 15min)</MenuItem>
                <MenuItem value="short">Short (15-30min)</MenuItem>
                <MenuItem value="medium">Medium (30-60min)</MenuItem>
                <MenuItem value="long">Long (1-2hrs)</MenuItem>
                <MenuItem value="extended">Extended (2hrs+)</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Notes Section */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={2}
              label="Additional Notes"
              value={task.notes || ''}
              onChange={(e) =>
                onEdit(task.id, { ...task, notes: e.target.value })
              }
              placeholder="Add any additional notes, context, or reminders..."
              size="small"
            />
          </Grid>
        </Grid>
      </TabPanel>

      {/* Checklist & Links Tab */}
      <TabPanel value={activeTab} index={1}>
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            Checklist
            <IconButton
              size="small"
              onClick={() => {
                const newChecklist = [
                  ...(task.checklist || []),
                  { id: Date.now(), text: '', completed: false },
                ];
                onEdit(task.id, { ...task, checklist: newChecklist });
              }}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          </Typography>

          {task.checklist?.map((item, index) => (
            <Box key={item.id} sx={{ display: 'flex', gap: 1, mb: 1 }}>
              <Checkbox
                checked={item.completed}
                onChange={(e) => {
                  const newChecklist = [...task.checklist];
                  newChecklist[index] = {
                    ...item,
                    completed: e.target.checked,
                  };
                  onEdit(task.id, { ...task, checklist: newChecklist });
                }}
                size="small"
              />
              <TextField
                value={item.text}
                onChange={(e) => {
                  const newChecklist = [...task.checklist];
                  newChecklist[index] = { ...item, text: e.target.value };
                  onEdit(task.id, { ...task, checklist: newChecklist });
                }}
                size="small"
                fullWidth
                placeholder="Checklist item"
              />
              <IconButton
                size="small"
                onClick={() => {
                  const newChecklist = task.checklist.filter(
                    (_, i) => i !== index,
                  );
                  onEdit(task.id, { ...task, checklist: newChecklist });
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            Related Links
            <IconButton
              size="small"
              onClick={() => {
                const newLinks = [
                  ...(task.links || []),
                  { id: Date.now(), url: '', title: '' },
                ];
                onEdit(task.id, { ...task, links: newLinks });
              }}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          </Typography>

          {task.links?.map((link, index) => (
            <Box key={link.id} sx={{ display: 'flex', gap: 1, mb: 1 }}>
              <TextField
                value={link.title}
                onChange={(e) => {
                  const newLinks = [...task.links];
                  newLinks[index] = { ...link, title: e.target.value };
                  onEdit(task.id, { ...task, links: newLinks });
                }}
                size="small"
                placeholder="Link title"
                sx={{ width: '30%' }}
              />
              <TextField
                value={link.url}
                onChange={(e) => {
                  const newLinks = [...task.links];
                  newLinks[index] = { ...link, url: e.target.value };
                  onEdit(task.id, { ...task, links: newLinks });
                }}
                size="small"
                placeholder="URL"
                fullWidth
              />
              <IconButton
                size="small"
                onClick={() => {
                  const newLinks = task.links.filter((_, i) => i !== index);
                  onEdit(task.id, { ...task, links: newLinks });
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Box>
      </TabPanel>

      {/* Priority Tab */}
      <TabPanel value={activeTab} index={2}>
        <Box>
          <Box
            sx={{
              mb: 3,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="subtitle1" gutterBottom>
              Task Priority Scoring
            </Typography>
            <Button
              variant="outlined"
              size="small"
              startIcon={<AutorenewIcon />}
              onClick={async () => {
                const scores = await analyzeTask(task);
                onEdit(task.id, {
                  ...task,
                  scores,
                  lastAnalyzed: new Date().toISOString(),
                });
              }}
            >
              Auto-Score
            </Button>
          </Box>

          <Grid container spacing={3}>
            {/* Urgency Score */}
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Urgency Score
              </Typography>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Slider
                  value={urgencyScore}
                  onChange={(_, value) => handleScoreChange('urgency', value, setUrgencyScore)}
                  step={1}
                  marks
                  min={0}
                  max={10}
                  valueLabelDisplay="auto"
                  sx={{ flexGrow: 1 }}
                />
                <Typography variant="body2" sx={{ minWidth: 30 }}>
                  {urgencyScore}/10
                </Typography>
              </Box>
            </Grid>

            {/* Complexity Score */}
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Complexity Score
              </Typography>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Slider
                  value={complexityScore}
                  onChange={(_, value) => handleScoreChange('complexity', value, setComplexityScore)}
                  step={1}
                  marks
                  min={0}
                  max={10}
                  valueLabelDisplay="auto"
                  sx={{ flexGrow: 1 }}
                />
                <Typography variant="body2" sx={{ minWidth: 30 }}>
                  {complexityScore}/10
                </Typography>
              </Box>
            </Grid>

            {/* Clarity Score */}
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Clarity Score
              </Typography>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Slider
                  value={clarityScore}
                  onChange={(_, value) => handleScoreChange('clarity', value, setClarityScore)}
                  step={1}
                  marks
                  min={0}
                  max={10}
                  valueLabelDisplay="auto"
                  sx={{ flexGrow: 1 }}
                />
                <Typography variant="body2" sx={{ minWidth: 30 }}>
                  {clarityScore}/10
                </Typography>
              </Box>
            </Grid>

            {/* Impact Score */}
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Impact Score
              </Typography>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Slider
                  value={impactScore}
                  onChange={(_, value) => handleScoreChange('impact', value, setImpactScore)}
                  step={1}
                  marks
                  min={0}
                  max={10}
                  valueLabelDisplay="auto"
                  sx={{ flexGrow: 1 }}
                />
                <Typography variant="body2" sx={{ minWidth: 30 }}>
                  {impactScore}/10
                </Typography>
              </Box>
            </Grid>

            {/* Priority Score Summary */}
            <Grid item xs={12}>
              <Box
                sx={{
                  mt: 2,
                  p: 2,
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                }}
              >
                <Typography variant="subtitle2" gutterBottom>
                  Overall Priority Score
                </Typography>
                <Typography variant="h4" color="primary">
                  {(calculatePriorityScore(task).total || 0).toFixed(1)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Last analyzed:{' '}
                  {task.lastAnalyzed
                    ? new Date(task.lastAnalyzed).toLocaleString()
                    : 'Never'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
    </Box>
  );
};

// TabPanel helper component
const TabPanel = ({ children, value, index, ...other }) => (
  <div role="tabpanel" hidden={value !== index} {...other}>
    {value === index && <Box sx={{ p: 1.5 }}>{children}</Box>}
  </div>
);

export default TaskDetails;
