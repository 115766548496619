import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase';

const ProtectedRoute = ({ children }) => {
  const [user, loading] = useAuthState(auth);
  const location = useLocation();

  if (loading) {
    return <div>Loading...</div>; // Optional loading state
  }

  if (!user) {
    // Redirect to the sign-in page if the user is not authenticated
    return <Navigate to="/app/signin" state={{ from: location }} replace />;
  }

  return children; // Render the protected component if authenticated
};

export default ProtectedRoute;
