import React, { useState } from 'react';
import { 
  Box, 
  TextField, 
  IconButton, 
  InputAdornment,
  Tooltip 
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const NewTaskInput = ({ onAddTask }) => {
  const [newTask, setNewTask] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newTask.trim()) {
      onAddTask({ title: newTask.trim() });
      setNewTask('');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        variant="standard"
        placeholder="Add a new task... (Press Enter to add)"
        value={newTask}
        onChange={(e) => setNewTask(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Add task">
                <IconButton 
                  edge="end" 
                  onClick={handleSubmit}
                  disabled={!newTask.trim()}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          )
        }}
        sx={{
          '& .MuiInput-underline:before': {
            borderBottom: 'none'
          },
          '& .MuiInput-underline:hover:before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
          }
        }}
      />
    </form>
  );
};

export default NewTaskInput; 