import React, { useState } from 'react';
import { 
  Paper, Box, Typography, Chip, IconButton, Stack, Tooltip,
  Collapse, Divider, Button
} from '@mui/material';
import { 
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon
} from '@mui/icons-material';
import { format } from 'date-fns';

const WorklogEntry = ({ entry, onDelete }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Paper 
      elevation={0}
      sx={{ 
        backgroundColor: 'background.paper',
        '&:hover': {
          backgroundColor: 'action.hover',
        },
      }}
    >
      <Box 
        sx={{ 
          p: 2,
          display: 'flex',
          alignItems: 'flex-start',
          gap: 2,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
            <Typography variant="caption" color="text.secondary">
              {format(new Date(entry.date), 'MMM d, yyyy')}
            </Typography>
            <Chip
              label={entry.category}
              size="small"
              color="primary"
              variant="outlined"
            />
          </Box>
          <Typography 
            sx={{ 
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: expanded ? 'unset' : 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {entry.content}
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton size="small" onClick={() => setExpanded(!expanded)}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          <IconButton size="small" onClick={() => onDelete(entry.id)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      
      <Collapse in={expanded}>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            {entry.tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                size="small"
                variant="outlined"
              />
            ))}
          </Box>
        </Box>
      </Collapse>
    </Paper>
  );
};

const WorklogListView = ({ entries, onDelete }) => {
  const [selected, setSelected] = useState([]);

  const handleSelect = (id) => {
    setSelected(prev => 
      prev.includes(id) 
        ? prev.filter(item => item !== id)
        : [...prev, id]
    );
  };

  const handleDeleteSelected = () => {
    selected.forEach(onDelete);
    setSelected([]);
  };

  return (
    <Box>
      {selected.length > 0 && (
        <Paper sx={{ mb: 2, p: 2 }} elevation={0}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography>{selected.length} items selected</Typography>
            <Button
              startIcon={<DeleteIcon />}
              color="error"
              onClick={handleDeleteSelected}
            >
              Delete Selected
            </Button>
          </Box>
        </Paper>
      )}
      <Stack 
        spacing={1}
        sx={{ 
          border: 1,
          borderColor: 'divider',
          borderRadius: 1,
          overflow: 'hidden',
        }}
      >
        {entries.map((entry) => (
          <React.Fragment key={entry.id}>
            <WorklogEntry entry={entry} onDelete={onDelete} />
            <Divider />
          </React.Fragment>
        ))}
      </Stack>
    </Box>
  );
};

export default WorklogListView; 