import { createTheme } from '@mui/material/styles';

const getUnifiedTheme = (mode) => {
  const themeColors = {
    light: {
      background: '#FFFFFF',
      primary: '#000000',
      secondary: '#F2F2F2',
      accent: '#000000',
      textPrimary: '#000000',
      textSecondary: '#4F4F4F',
      cardBackground: '#FFFFFF',
      buttonPrimaryBackground: '#000000',
      buttonPrimaryText: '#FFFFFF',
      buttonPrimaryHover: '#333333',
      buttonSecondaryBackground: 'transparent',
      buttonSecondaryText: '#000000',
      buttonSecondaryBorder: '#000000',
      buttonSecondaryHoverBackground: 'rgba(0, 0, 0, 0.1)',
    },
    dark: {
      background: '#000000',
      primary: '#FFFFFF',
      secondary: '#1A1A1A',
      accent: '#FFFFFF',
      textPrimary: '#FFFFFF',
      textSecondary: '#CCCCCC',
      cardBackground: '#1A1A1A',
      buttonPrimaryBackground: '#FFFFFF',
      buttonPrimaryText: '#000000',
      buttonPrimaryHover: '#E5E5E5',
      buttonSecondaryBackground: 'transparent',
      buttonSecondaryText: '#FFFFFF',
      buttonSecondaryBorder: '#FFFFFF',
      buttonSecondaryHoverBackground: 'rgba(255, 255, 255, 0.1)',
    }
  };

  const colors = themeColors[mode];

  return createTheme({
    palette: {
      mode,
      primary: {
        main: colors.primary,
        light: colors.accent,
        dark: colors.buttonPrimaryHover,
      },
      secondary: {
        main: colors.secondary,
      },
      background: {
        default: colors.background,
        paper: colors.cardBackground,
      },
      text: {
        primary: colors.textPrimary,
        secondary: colors.textSecondary,
      },
      customColors: colors, // Add all colors to theme for easy access
    },
    // Keep existing component styles from theme.js
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderRadius: 4,
          },
        },
      },
      // ... other component styles
    },
  });
};

export const createUnifiedTheme = (mode) => getUnifiedTheme(mode); 