import React from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ children, isAuthenticated }) => {
  // If the user is logged in, redirect to the dashboard
  if (isAuthenticated) {
    return <Navigate to="/app/dashboard" />;
  }

  // Otherwise, render the public route
  return children;
};

export default PublicRoute;
