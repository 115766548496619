import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Switch,
  FormControlLabel,
} from '@mui/material';

const Profile = () => {
  const [user] = useAuthState(auth);
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    accountStatus: '',
    deleted: false,
    firstName: '',
    lastName: '',
    subscriptionStatus: '',
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const data = userDocSnap.data();
            setUserData(data);
            setFormData({
              accountStatus: data.accountStatus || '',
              deleted: data.deleted || false,
              firstName: data.firstName || '',
              lastName: data.lastName || '',
              subscriptionStatus: data.subscriptionStatus || '',
            });
          } else {
            // User data doesn't exist (new user)
            setUserData({
              accountStatus: '',
              deleted: false,
              firstName: '',
              lastName: '',
              subscriptionStatus: '',
            });
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          // Handle error, e.g., show error message
        }
      }
    };

    fetchUserData();
  }, [user]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    try {
      await updateDoc(doc(db, 'users', user.uid), formData);
      setUserData(formData);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating user data:', error);
      // Handle error
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
          Profile
        </Typography>

        {userData && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <TextField
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              disabled={!isEditing}
              fullWidth
            />
            <TextField
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              disabled={!isEditing}
              fullWidth
            />
            <TextField
              label="Account Status"
              name="accountStatus"
              value={formData.accountStatus}
              onChange={handleChange}
              disabled={!isEditing}
              fullWidth
            />
            <TextField
              label="Subscription Status"
              name="subscriptionStatus"
              value={formData.subscriptionStatus}
              onChange={handleChange}
              disabled={!isEditing}
              fullWidth
            />
            {/* Boolean field for 'deleted' */}
            <FormControlLabel
              control={
                <Switch
                  checked={formData.deleted}
                  onChange={handleChange}
                  name="deleted"
                  disabled={!isEditing}
                />
              }
              label="Deleted"
            />

            {isEditing ? (
              <Button variant="contained" onClick={handleSaveClick}>
                Save
              </Button>
            ) : (
              <Button variant="outlined" onClick={handleEditClick}>
                Edit
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Profile;
