import React, { useState } from 'react';
import { 
  Box,
  Chip,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { 
  DataGrid, 
  GridActionsCellItem,
} from '@mui/x-data-grid';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material';
import { format } from 'date-fns';
import EditTaskModal from './EditTaskModal';
import { alpha } from '@mui/material/styles';
import styled from '@emotion/styled';

const DataGridStyled = styled(DataGrid)(({ theme }) => ({
  border: 'none',
  backgroundColor: theme.palette.background.paper,
  '& .MuiDataGrid-row': {
    minHeight: '60px !important',
    maxHeight: '60px !important',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  '& .MuiDataGrid-cell': {
    minHeight: '60px !important',
    maxHeight: '60px !important',
    borderColor: theme.palette.divider,
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'visible',
    whiteSpace: 'normal',
    '&:focus': {
      outline: 'none',
    },
  },
  '& .MuiDataGrid-columnHeaders': {
    minHeight: '44px !important',
    maxHeight: '44px !important',
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  '& .MuiDataGrid-columnHeader': {
    minHeight: '44px !important',
    maxHeight: '44px !important',
    '&:focus': {
      outline: 'none',
    },
  },
  '& .super-app-theme--header': {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    fontSize: '0.875rem',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  '& .MuiCheckbox-root': {
    color: theme.palette.text.secondary,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
  '& .MuiDataGrid-virtualScroller': {
    backgroundColor: theme.palette.background.paper,
  },
}));

const TaskTableView = ({ tasks, onEdit, onDelete }) => {
  const theme = useTheme();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [editingTask, setEditingTask] = useState(null);

  const handleEditClick = (task) => {
    setSelectedTask({...task});
    setEditingTask({...task});
    setEditModalOpen(true);
  };

  const handleEditClose = () => {
    setEditModalOpen(false);
    setTimeout(() => {
      setSelectedTask(null);
      setEditingTask(null);
    }, 300);
  };

  const handleEdit = (id, updatedTask) => {
    if (editModalOpen) {
      setEditingTask(prevTask => ({
        ...prevTask,
        ...updatedTask
      }));
      
      if (updatedTask.isSave) {
        const taskToSave = {
          ...updatedTask
        };
        delete taskToSave.isSave;
        onEdit(id, taskToSave);
        handleEditClose();
      }
    } else {
      onEdit(id, updatedTask);
    }
  };

  const columns = [
    {
      field: 'status',
      headerName: 'Status',
      width: 70,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onEdit(params.row.id, {
                ...params.row,
                status: params.row.status === 'completed' ? 'pending' : 'completed'
              });
            }}
            size="small"
            sx={{ 
              '&:hover': { 
                backgroundColor: alpha(theme.palette.primary.main, 0.1) 
              } 
            }}
          >
            {params.row.status === 'completed' ? (
              <CheckCircleIcon color="primary" fontSize="small" />
            ) : (
              <RadioButtonUncheckedIcon 
                fontSize="small"
                sx={{ color: theme.palette.text.secondary }} 
              />
            )}
          </IconButton>
        </Box>
      ),
    },
    {
      field: 'title',
      headerName: 'Task',
      flex: 1,
      minWidth: 250,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          width: '100%',
          height: '100%',
          pl: 1,
        }}>
          <Typography 
            variant="body2" 
            sx={{ 
              color: params.row.status === 'completed' 
                ? 'text.secondary' 
                : 'text.primary',
              textDecoration: params.row.status === 'completed' 
                ? 'line-through' 
                : 'none',
              fontWeight: params.row.status === 'completed' 
                ? 'normal' 
                : 500,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '100%',
            }}
          >
            {params.row.title}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'priority',
      headerName: 'Priority',
      width: 120,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        if (!params.row.priority) return null;
        const priorityColors = {
          high: theme.palette.error.main,
          medium: theme.palette.warning.main,
          low: theme.palette.success.main,
        };
        return (
          <Chip
            label={params.row.priority.charAt(0).toUpperCase() + params.row.priority.slice(1)}
            size="small"
            sx={{
              borderRadius: '4px',
              backgroundColor: `${priorityColors[params.row.priority]}15`,
              color: priorityColors[params.row.priority],
              border: 'none',
              fontWeight: 500,
              fontSize: '0.75rem',
            }}
          />
        );
      },
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      width: 130,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        if (!params.row.dueDate) return '';
        try {
          return (
            <Typography variant="body2" color="text.secondary">
              {format(new Date(params.row.dueDate), 'MMM d, yyyy')}
            </Typography>
          );
        } catch {
          return '';
        }
      },
    },
    {
      field: 'timeEstimate',
      headerName: 'Time Est.',
      width: 120,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const estimates = {
          quickWin: '< 15min',
          short: '15-30min',
          medium: '30-60min',
          long: '1-2hrs',
          extended: '2hrs+'
        };
        return (
          <Typography variant="body2" color="text.secondary">
            {estimates[params.row.timeEstimate] || ''}
          </Typography>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => handleEditClick(params.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => onDelete(params.id)}
        />,
      ],
    },
  ];

  const processedTasks = tasks.map(task => ({
    id: task.id || Math.random().toString(36).substr(2, 9),
    title: task.title || '',
    ...task
  }));

  return (
    <>
      <Paper 
        elevation={0} 
        sx={{ 
          height: 'calc(100vh - 250px)',
          width: '100%',
          borderRadius: 2,
          overflow: 'hidden',
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <DataGridStyled
          rows={processedTasks}
          columns={columns}
          pageSize={25}
          rowsPerPageOptions={[10, 25, 50]}
          checkboxSelection
          disableSelectionOnClick
          getRowHeight={() => 60}
          sx={{
            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: theme.palette.action.selected,
              '&:hover': {
                backgroundColor: theme.palette.action.selected,
              },
            },
          }}
        />
      </Paper>

      {selectedTask && (
        <EditTaskModal
          open={editModalOpen}
          onClose={handleEditClose}
          task={editingTask}
          onEdit={handleEdit}
        />
      )}
    </>
  );
};

export default TaskTableView; 