import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export const useWorklogStore = create(
  persist(
    (set) => ({
      entries: [],
      
      addEntry: (data) => {
        const newEntry = {
          id: Date.now().toString(),
          date: data.date || new Date().toISOString(),
          content: data.content,
          category: data.category || 'feature',
          tags: data.tags || [],
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
        
        set((state) => ({
          entries: [newEntry, ...state.entries],
        }));
        
        return newEntry;
      },
      
      updateEntry: (id, updates) => {
        set((state) => ({
          entries: state.entries.map((entry) =>
            entry.id === id
              ? {
                  ...entry,
                  ...updates,
                  updatedAt: new Date().toISOString(),
                }
              : entry
          ),
        }));
      },
      
      deleteEntry: (id) =>
        set((state) => ({
          entries: state.entries.filter((entry) => entry.id !== id),
        })),
    }),
    {
      name: 'worklog-storage',
      storage: createJSONStorage(() => localStorage),
      version: 1,
    }
  )
); 