import React, { useState } from 'react';
import { format } from 'date-fns';
import { 
  Paper, 
  Typography, 
  Box, 
  IconButton, 
  Tooltip, 
  Fade,
  Chip,
  useTheme
} from '@mui/material';
import {
  LocationOn,
  VideoCall,
  Edit,
  Delete,
  DragIndicator as DragHandle
} from '@mui/icons-material';

const TimelineMeetingCard = ({ meeting, top, height, onEdit, onDelete, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();

  return (
    <Paper
      elevation={isHovered ? 3 : 1}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
      sx={{
        position: 'absolute',
        left: '8px',
        right: '8px',
        top: `${top}px`,
        height: `${height}px`,
        backgroundColor: theme.palette.background.paper,
        borderLeft: `4px solid ${theme.palette.primary.main}`,
        color: theme.palette.text.primary,
        borderRadius: 1,
        overflow: 'hidden',
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        transform: isHovered ? 'translateX(2px)' : 'none',
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.divider}`,
        '&:hover': {
          backgroundColor: theme.palette.mode === 'dark' ? '#333333' : '#E0E0E0',
        }
      }}
    >
      {/* Meeting Content */}
      <Box sx={{ p: 1, flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ mb: 0.5 }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 600, lineHeight: 1.2 }} noWrap>
            {meeting.title}
          </Typography>
          <Typography 
            variant="caption" 
            sx={{ 
              color: theme.palette.text.secondary,
              display: 'block'
            }}
          >
            {`${format(new Date(meeting.startTime), 'HH:mm')} - 
              ${format(new Date(meeting.endTime), 'HH:mm')}`}
          </Typography>
        </Box>

        {height > 80 && (
          <Fade in={true}>
            <Box sx={{ mt: 'auto' }}>
              {meeting.location && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
                  <LocationOn 
                    fontSize="small" 
                    sx={{ color: theme.palette.text.secondary }} 
                  />
                  <Typography 
                    variant="caption" 
                    sx={{ color: theme.palette.text.secondary }}
                    noWrap
                  >
                    {meeting.location}
                  </Typography>
                </Box>
              )}

              {meeting.isOnline && (
                <Chip
                  icon={<VideoCall fontSize="small" />}
                  label="Online"
                  size="small"
                  variant="outlined"
                  sx={{ 
                    height: 20,
                    borderColor: theme.palette.divider,
                    color: theme.palette.text.secondary,
                  }}
                />
              )}
            </Box>
          </Fade>
        )}
      </Box>

      {/* Hover Actions */}
      <Fade in={isHovered}>
        <Box
          sx={{
            position: 'absolute',
            top: 4,
            right: 4,
            display: 'flex',
            gap: 0.5,
            backgroundColor: theme.palette.background.paper,
            borderRadius: 1,
            padding: '2px',
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          <IconButton 
            size="small" 
            onClick={(e) => {
              e.stopPropagation();
              onEdit(meeting);
            }}
            sx={{
              color: theme.palette.text.primary,
              '&:hover': {
                backgroundColor: theme.palette.mode === 'dark' ? '#333333' : '#E0E0E0',
              }
            }}
          >
            <Edit fontSize="small" />
          </IconButton>
          <IconButton 
            size="small" 
            onClick={(e) => {
              e.stopPropagation();
              onDelete(meeting.id);
            }}
            sx={{
              color: theme.palette.error.main,
              '&:hover': {
                backgroundColor: theme.palette.mode === 'dark' ? '#333333' : '#E0E0E0',
              }
            }}
          >
            <Delete fontSize="small" />
          </IconButton>
        </Box>
      </Fade>
    </Paper>
  );
};

export default TimelineMeetingCard; 