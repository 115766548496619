import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useMeetingStore = create(
  persist(
    (set) => ({
      meetings: [],
      selectedDate: new Date().toISOString(),

      setSelectedDate: (date) => set({ selectedDate: new Date(date).toISOString() }),

      timelineConfig: {
        startHour: 0,
        endHour: 24,
        workingHoursStart: '09:00',
        workingHoursEnd: '17:00',
        showWorkingHours: true,
      },

      addMeeting: (meetingData) =>
        set((state) => ({
          meetings: [
            ...state.meetings,
            {
              id: Date.now().toString(),
              title: meetingData.title,
              startTime: new Date(meetingData.startTime).toISOString(),
              endTime: new Date(meetingData.endTime).toISOString(),
              location: meetingData.location || '',
              description: meetingData.description || '',
              isOnline: meetingData.isOnline || false,
            },
          ],
        })),

      updateMeeting: (meetingId, updates) =>
        set((state) => ({
          meetings: state.meetings.map((meeting) =>
            meeting.id === meetingId
              ? {
                  ...meeting,
                  title: updates.title,
                  startTime: new Date(updates.startTime).toISOString(),
                  endTime: new Date(updates.endTime).toISOString(),
                  location: updates.location || '',
                  description: updates.description || '',
                  isOnline: updates.isOnline || false,
                }
              : meeting,
          ),
        })),

      deleteMeeting: (meetingId) =>
        set((state) => ({
          meetings: state.meetings.filter(
            (meeting) => meeting.id !== meetingId,
          ),
        })),

      updateTimelineConfig: (config) =>
        set((state) => ({
          timelineConfig: {
            ...state.timelineConfig,
            ...config,
          },
        })),
    }),
    {
      name: 'meetings-storage',
    },
  ),
);

export default useMeetingStore;
