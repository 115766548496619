import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TaskDetails from './TaskDetails';

const EditTaskModal = ({ open, onClose, task, onEdit }) => {
  if (!task) return null;

  const handleEdit = (id, updatedTask) => {
    // Regular updates (like typing) just call onEdit without isSave flag
    onEdit(id, updatedTask);
  };

  const handleSave = () => {
    // Add isSave flag when saving
    onEdit(task.id, { ...task, isSave: true });
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          backgroundColor: 'background.paper',
        }
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          Edit Task
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <TaskDetails 
          task={task} 
          onEdit={handleEdit}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleSave}
          variant="contained"
          color="primary"
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTaskModal; 