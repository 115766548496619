import React from 'react';
import { 
  ToggleButtonGroup, 
  ToggleButton, 
  Tooltip 
} from '@mui/material';
import { 
  ViewList, 
  ViewModule, 
  TableChart 
} from '@mui/icons-material';

const TaskViewSelector = ({ currentView, onViewChange }) => {
  return (
    <ToggleButtonGroup
      value={currentView}
      exclusive
      onChange={(e, value) => value && onViewChange(value)}
      size="small"
      aria-label="task view"
      sx={{ mb: 2 }}
    >
      <ToggleButton value="list">
        <Tooltip title="List View">
          <ViewList />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value="table">
        <Tooltip title="Table View">
          <TableChart />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value="kanban" disabled>
        <Tooltip title="Kanban View (Coming Soon)">
          <ViewModule />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default TaskViewSelector; 