// Import the scoring algorithm we developed earlier
// This file will contain all the calculation logic we discussed

// Add these helper functions at the top of the file
const calculateDaysUntilDue = (dueDate) => {
  const now = new Date();
  const due = new Date(dueDate);
  const diffTime = due - now;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const getDeadlineScore = (daysUntilDue) => {
  if (daysUntilDue <= 0) return 10;  // Due today/overdue
  if (daysUntilDue <= 1) return 9;   // Due tomorrow
  if (daysUntilDue <= 3) return 8;   // Due within 3 days
  if (daysUntilDue <= 7) return 7;   // Due within a week
  if (daysUntilDue <= 14) return 5;  // Due within 2 weeks
  if (daysUntilDue <= 30) return 3;  // Due within a month
  return 1;                          // Due later
};

const getImpactScore = (impact) => {
  const impactScores = {
    blocking: 10,
    high: 8,
    medium: 5,
    low: 3,
    minimal: 1
  };
  return impactScores[impact] || 0;
};

const getTimeEstimateScore = (estimate) => {
  const estimateScores = {
    quickWin: 2,     // < 15 mins
    short: 4,        // 15-30 mins
    medium: 6,       // 30-60 mins
    long: 8,         // 1-2 hours
    extended: 10     // > 2 hours
  };
  return estimateScores[estimate] || 5;
};

const getStepsScore = (numSteps) => {
  if (numSteps <= 1) return 2;
  if (numSteps <= 3) return 4;
  if (numSteps <= 5) return 6;
  if (numSteps <= 8) return 8;
  return 10;
};

const getDependencyScore = (numDependencies) => {
  if (numDependencies === 0) return 0;
  if (numDependencies <= 2) return 3;
  if (numDependencies <= 4) return 6;
  return 10;
};

const getDefinitionScore = (description) => {
  if (!description) return 0;
  const words = description.trim().split(/\s+/).length;
  if (words >= 50) return 10;  // Well defined
  if (words >= 30) return 8;   // Mostly defined
  if (words >= 15) return 5;   // Partially defined
  if (words >= 5) return 3;    // Minimally defined
  return 1;                    // Poorly defined
};

const getResourceScore = (resources) => {
  if (!resources || resources.length === 0) return 0;
  const availableResources = resources.filter(r => r.available).length;
  const totalResources = resources.length;
  return Math.round((availableResources / totalResources) * 10);
};

// Add this function to the exports
export const calculatePriorityScore = (task) => {
  if (!task || !task.scores) {
    return {
      total: 0,
      components: {
        urgency: 0,
        complexity: 0,
        clarity: 0,
        impact: 0
      },
      level: 'LOW'
    };
  }

  // Updated weights to include impact (total still equals 1)
  const weights = {
    urgency: 0.35,
    complexity: 0.25,
    clarity: 0.2,
    impact: 0.2
  };

  // Ensure all scores have default values
  const scores = {
    urgency: task.scores.urgency || 0,
    complexity: task.scores.complexity || 0,
    clarity: task.scores.clarity || 0,
    impact: task.scores.impact || 0
  };

  const weightedScore = (
    (scores.urgency * weights.urgency) +
    (scores.complexity * weights.complexity) +
    (scores.clarity * weights.clarity) +
    (scores.impact * weights.impact)
  );

  const normalizedScore = Math.min(Math.max(weightedScore, 0), 10);

  return {
    total: normalizedScore,
    components: scores,
    level: getPriorityLevel(normalizedScore)
  };
};

// Add this helper function as well
const getPriorityLevel = (score) => {
  if (score >= 8.5) return 'CRITICAL';
  if (score >= 7) return 'HIGH';
  if (score >= 5) return 'MEDIUM';
  return 'LOW';
};

// Export getPriorityLevel if needed elsewhere
export { getPriorityLevel };

// Export all the helper functions
export {
  calculateDaysUntilDue,
  getDeadlineScore,
  getImpactScore,
  getTimeEstimateScore,
  getStepsScore,
  getDependencyScore,
  getDefinitionScore,
  getResourceScore
};

export const analyzeTask = async (task) => {
  try {
    // Simulate API call delay
    await new Promise(resolve => setTimeout(resolve, 500));

    // Generate semi-intelligent scores based on task properties
    const baseUrgency = task.dueDate ? 
      10 - Math.min(calculateDaysUntilDue(task.dueDate), 10) : 
      Math.floor(Math.random() * 5);

    const baseComplexity = task.description ? 
      Math.min(task.description.length / 50, 10) : 
      Math.floor(Math.random() * 5);

    const baseClarity = task.description ? 
      Math.min(task.description.length / 100, 10) : 
      Math.floor(Math.random() * 5);

    // Add impact score generation
    const baseImpact = task.priority === 'high' ? 
      7 + Math.floor(Math.random() * 4) : // High priority tasks get 7-10 impact
      Math.floor(Math.random() * 7);      // Other tasks get 0-6 impact

    // Add some randomness
    const randomFactor = () => (Math.random() - 0.5) * 2;

    // Ensure scores are within bounds
    const normalizeScore = (score) => Math.min(Math.max(Math.round(score), 0), 10);

    return {
      urgency: normalizeScore(baseUrgency + randomFactor()),
      complexity: normalizeScore(baseComplexity + randomFactor()),
      clarity: normalizeScore(baseClarity + randomFactor()),
      impact: normalizeScore(baseImpact + randomFactor())
    };

  } catch (error) {
    console.error('Error analyzing task:', error);
    throw error;
  }
};

// When ready to implement AI scoring, you can modify the analyzeTask function:
/*
export const analyzeTask = async (task) => {
  try {
    const response = await fetch('/api/analyze-task', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(task)
    });

    if (!response.ok) {
      throw new Error('Failed to analyze task');
    }

    return await response.json();
  } catch (error) {
    console.error('Error analyzing task:', error);
    throw error;
  }
};
*/

export const getTaskSuggestions = (task) => {
  // Implementation of our suggestion system
  // For now, return some sample suggestions
  return [
    {
      type: 'BREAKDOWN',
      message: 'This task seems complex. Break it down?'
    },
    {
      type: 'TIME_ALERT',
      message: 'Set up reminders for this task?'
    }
  ];
}; 