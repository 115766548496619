// src/pages/Insights.jsx
import React, { useState } from 'react';
import { Box, Typography, Chip, Button } from '@mui/material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LightbulbOutlineIcon from '@mui/icons-material/LightbulbOutlined';
import MeetingsTimelineLayout from '../components/Layouts/MeetingsTimelineLayout';
import useMeetingStore from '../store/useMeetingStore';
import NewMeeting from '../components/Meeting/NewMeeting';

const Meetings = () => {
  const [isNewMeetingOpen, setIsNewMeetingOpen] = useState(false);
  const meetings = useMeetingStore(state => state.meetings);
  const addMeeting = useMeetingStore(state => state.addMeeting);
  
  const upcomingMeetings = meetings.filter(meeting => 
    new Date(meeting.startTime) > new Date()
  );

  return (
    <Box>
      <Box 
        sx={{ 
          py: 3,
          px: 4,
          backgroundColor: 'background.paper',
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h5" fontWeight="600">
              Meetings Schedule
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={<CalendarMonthOutlinedIcon />}
            onClick={() => setIsNewMeetingOpen(true)}
          >
            New Meeting
          </Button>
        </Box>
        
        <Box display="flex" gap={2} alignItems="center">
          <Chip 
            label={`${upcomingMeetings.length} upcoming meetings`}
            color="primary" 
            variant="outlined"
          />
          <Box display="flex" alignItems="center" gap={1}>
            <LightbulbOutlineIcon color="action" fontSize="small" />
            <Typography variant="body2" color="text.secondary">
              Pro tip: Schedule meetings with clear agendas
            </Typography>
          </Box>
        </Box>
      </Box>
      <MeetingsTimelineLayout />
      <NewMeeting
        open={isNewMeetingOpen}
        onClose={() => setIsNewMeetingOpen(false)}
        onAdd={addMeeting}
      />
    </Box>
  );
};

export default Meetings;
