import React, { useEffect, useState, useMemo, createContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  CircularProgress,
  Box,
} from '@mui/material';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import Layout from './components/Layouts/Layout';
import ProtectedRoute from './components/Layouts/ProtectedRoute';
import PublicRoute from './components/Layouts/PublicRoute';
import { createUnifiedTheme } from './theme/unifiedTheme';
import { ConfirmProvider } from 'material-ui-confirm';
import { publicRoutes, protectedRoutes } from './routes';

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [mode, setMode] = useState('dark');
  const theme = useMemo(() => createUnifiedTheme(mode), [mode]);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      setUser(authUser);
      setIsLoading(false);
    });
    return unsubscribe;
  }, []);

  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ConfirmProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              {/* Public Routes */}
              {publicRoutes.map(({ path, element: Element }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <PublicRoute isAuthenticated={!!user}>
                      <Element />
                    </PublicRoute>
                  }
                />
              ))}

              {/* Protected Routes */}
              <Route
                path="/app"
                element={
                  <ProtectedRoute isAuthenticated={!!user}>
                    <Layout />
                  </ProtectedRoute>
                }
              >
                {protectedRoutes.map(({ path, element: Element }) => (
                  <Route 
                    key={path} 
                    path={path} 
                    element={<Element />} 
                  />
                ))}
                <Route path="*" element={<Navigate to="/app/dashboard" />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </ConfirmProvider>
  );
};

export default App;
