import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Chip,
  IconButton,
} from '@mui/material';
import {
  LocationOn,
  VideoCall,
  Edit,
  Close,
} from '@mui/icons-material';
import { format } from 'date-fns';

const MeetingViewDialog = ({ open, meeting, onClose, onEdit }) => {
  if (!meeting) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{meeting.title}</Typography>
          <IconButton onClick={onClose} size="small">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Time */}
          <Box>
            <Typography variant="subtitle2" color="text.secondary">
              Time
            </Typography>
            <Typography>
              {format(new Date(meeting.startTime), 'EEEE, MMMM d, yyyy')}
              <br />
              {format(new Date(meeting.startTime), 'HH:mm')} - {format(new Date(meeting.endTime), 'HH:mm')}
            </Typography>
          </Box>

          {/* Location */}
          {meeting.location && (
            <Box>
              <Typography variant="subtitle2" color="text.secondary">
                Location
              </Typography>
              <Box display="flex" alignItems="center" gap={1}>
                <LocationOn fontSize="small" color="action" />
                <Typography>{meeting.location}</Typography>
              </Box>
            </Box>
          )}

          {/* Meeting Type */}
          {meeting.isOnline && (
            <Box>
              <Chip
                icon={<VideoCall />}
                label="Online Meeting"
                variant="outlined"
                size="small"
              />
            </Box>
          )}

          {/* Description */}
          {meeting.description && (
            <Box>
              <Typography variant="subtitle2" color="text.secondary">
                Description
              </Typography>
              <Typography>{meeting.description}</Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={onEdit} variant="contained">
          Edit Meeting
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MeetingViewDialog; 