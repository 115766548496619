import Dashboard from '../components/views/Dashboard';
import Tasks from '../pages/Tasks';
import FocusSessions from '../pages/FocusSessions';
import Insights from '../pages/Insights';
import Meetings from '../pages/Meetings';
import Help from '../pages/Help';
import Settings from '../pages/Settings';
import Profile from '../pages/Profile';
import SignIn from '../components/auth/SignIn';
import SignUp from '../components/auth/SignUp';
import Workspace from '../components/Workspace/Workspace';
import WorkspacesPage from '../pages/WorkspacesPage';
import WorklogPage from '../pages/WorklogPage';
import LandingPage from '../pages/LandingPage';

export const publicRoutes = [
  {
    path: '/',
    element: LandingPage,
  },
  {
    path: '/app/signin',
    element: SignIn,
  },
  {
    path: '/app/signup',
    element: SignUp,
  },
];

export const protectedRoutes = [
  {
    path: 'dashboard',
    element: Dashboard,
  },
  {
    path: 'tasks',
    element: Tasks,
  },
  {
    path: 'meetings',
    element: Meetings,
  },
  {
    path: 'sessions',
    element: FocusSessions,
  },
  {
    path: 'insights',
    element: Insights,
  },
  {
    path: 'settings',
    element: Settings,
  },
  {
    path: 'help',
    element: Help,
  },
  {
    path: 'profile',
    element: Profile,
  },
  {
    path: 'workspaces',
    element: WorkspacesPage,
  },
  {
    path: 'workspace/:workspaceId',
    element: Workspace,
  },
  {
    path: 'worklog',
    element: WorklogPage,
  },
];
