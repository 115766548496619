import CodeIcon from '@mui/icons-material/Code';
import BugReportIcon from '@mui/icons-material/BugReport';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import BuildIcon from '@mui/icons-material/Build';
import ScienceIcon from '@mui/icons-material/Science';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import GroupsIcon from '@mui/icons-material/Groups';
import EventIcon from '@mui/icons-material/Event';
import AssessmentIcon from '@mui/icons-material/Assessment';
import RefreshIcon from '@mui/icons-material/Refresh';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import RateReviewIcon from '@mui/icons-material/RateReview';
import ArticleIcon from '@mui/icons-material/Article';
import EmailIcon from '@mui/icons-material/Email';
import ForumIcon from '@mui/icons-material/Forum';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import TimelineIcon from '@mui/icons-material/Timeline';
import SchoolIcon from '@mui/icons-material/School';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export const TASK_CATEGORIES = [
  { value: 'project', label: 'Project Work' },
  { value: 'meeting', label: 'Meetings & Calls' },
  { value: 'review', label: 'Reviews & Feedback' },
  { value: 'documentation', label: 'Documentation' },
  { value: 'communication', label: 'Communication' },
  { value: 'planning', label: 'Planning & Strategy' },
  { value: 'research', label: 'Research & Learning' },
  { value: 'maintenance', label: 'Maintenance & Support' },
  { value: 'admin', label: 'Administrative' },
];

export const TASK_TYPES = {
  project: [
    { value: 'development', label: 'Development Task' },
    { value: 'bug_fix', label: 'Bug Fix' },
    { value: 'feature', label: 'Feature Implementation' },
    { value: 'refactoring', label: 'Code Refactoring' },
    { value: 'testing', label: 'Testing & QA' },
    { value: 'deployment', label: 'Deployment' },
  ],
  meeting: [
    { value: 'standup', label: 'Daily Standup' },
    { value: 'sprint_planning', label: 'Sprint Planning' },
    { value: 'retro', label: 'Retrospective' },
    { value: 'client_meeting', label: 'Client Meeting' },
    { value: '1on1', label: '1:1 Meeting' },
    { value: 'team_sync', label: 'Team Sync' },
  ],
  review: [
    { value: 'code_review', label: 'Code Review' },
    { value: 'pr_review', label: 'PR Review' },
    { value: 'design_review', label: 'Design Review' },
    { value: 'performance_review', label: 'Performance Review' },
  ],
  documentation: [
    { value: 'technical_docs', label: 'Technical Documentation' },
    { value: 'api_docs', label: 'API Documentation' },
    { value: 'user_guides', label: 'User Guides' },
    { value: 'process_docs', label: 'Process Documentation' },
  ],
  communication: [
    { value: 'email', label: 'Email Management' },
    { value: 'slack', label: 'Slack Communications' },
    { value: 'report', label: 'Status Report' },
    { value: 'presentation', label: 'Presentation Prep' },
  ],
  planning: [
    { value: 'roadmap', label: 'Roadmap Planning' },
    { value: 'sprint_planning', label: 'Sprint Planning' },
    { value: 'resource_planning', label: 'Resource Planning' },
    { value: 'estimation', label: 'Task Estimation' },
  ],
  research: [
    { value: 'tech_research', label: 'Technology Research' },
    { value: 'learning', label: 'Learning & Development' },
    { value: 'poc', label: 'Proof of Concept' },
    { value: 'analysis', label: 'Analysis & Investigation' },
  ],
  maintenance: [
    { value: 'monitoring', label: 'System Monitoring' },
    { value: 'updates', label: 'System Updates' },
    { value: 'backup', label: 'Backup & Recovery' },
    { value: 'optimization', label: 'Performance Optimization' },
  ],
  admin: [
    { value: 'reporting', label: 'Reporting' },
    { value: 'scheduling', label: 'Scheduling' },
    { value: 'coordination', label: 'Team Coordination' },
    { value: 'admin_task', label: 'Administrative Task' },
  ],
};

export const CATEGORY_ICONS = {
  project: () => <CodeIcon />,
  meeting: () => <EventIcon />,
  review: () => <RateReviewIcon />,
  documentation: () => <ArticleIcon />,
  communication: () => <EmailIcon />,
  planning: () => <TimelineIcon />,
  research: () => <SchoolIcon />,
  maintenance: () => <SettingsIcon />,
  admin: () => <AdminPanelSettingsIcon />,
};

export const TYPE_ICONS = {
  // Project types
  development: () => <CodeIcon />,
  bug_fix: () => <BugReportIcon />,
  feature: () => <ArchitectureIcon />,
  refactoring: () => <BuildIcon />,
  testing: () => <ScienceIcon />,
  deployment: () => <RocketLaunchIcon />,
  
  // Meeting types
  standup: () => <GroupsIcon />,
  sprint_planning: () => <AssessmentIcon />,
  retro: () => <RefreshIcon />,
  client_meeting: () => <BusinessIcon />,
  '1on1': () => <PersonIcon />,
  team_sync: () => <PeopleIcon />,
  
  // Review types
  code_review: () => <RateReviewIcon />,
  pr_review: () => <RateReviewIcon />,
  design_review: () => <RateReviewIcon />,
  performance_review: () => <RateReviewIcon />,
  
  // Documentation types
  technical_docs: () => <ArticleIcon />,
  api_docs: () => <ArticleIcon />,
  user_guides: () => <ArticleIcon />,
  process_docs: () => <ArticleIcon />,
  
  // Communication types
  email: () => <EmailIcon />,
  slack: () => <ForumIcon />,
  report: () => <ArticleIcon />,
  presentation: () => <SlideshowIcon />,
}; 