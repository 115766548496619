import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Switch,
  FormControlLabel
} from '@mui/material';

const NewMeeting = ({ open, onClose, onAdd, initialData, isEdit }) => {
  const [meetingData, setMeetingData] = useState({
    title: '',
    startTime: '',
    endTime: '',
    description: '',
    location: '',
    isOnline: false
  });

  useEffect(() => {
    if (initialData && open) {
      const formatDateForInput = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().slice(0, 16);
      };

      setMeetingData({
        title: initialData.title || '',
        startTime: formatDateForInput(initialData.startTime),
        endTime: formatDateForInput(initialData.endTime),
        description: initialData.description || '',
        location: initialData.location || '',
        isOnline: initialData.isOnline || false
      });
    } else if (!open) {
      // Reset form when closing
      setMeetingData({
        title: '',
        startTime: '',
        endTime: '',
        description: '',
        location: '',
        isOnline: false
      });
    }
  }, [initialData, open]);

  const handleSubmit = () => {
    const formattedData = {
      ...meetingData,
      startTime: new Date(meetingData.startTime).toISOString(),
      endTime: new Date(meetingData.endTime).toISOString(),
    };
    onAdd(formattedData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{isEdit ? 'Edit Meeting' : 'Schedule New Meeting'}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <TextField
            fullWidth
            label="Meeting Title"
            value={meetingData.title}
            onChange={(e) => setMeetingData({ ...meetingData, title: e.target.value })}
            required
          />
          
          <TextField
            fullWidth
            label="Start Time"
            type="datetime-local"
            value={meetingData.startTime}
            onChange={(e) => setMeetingData({ ...meetingData, startTime: e.target.value })}
            InputLabelProps={{ shrink: true }}
            required
          />
          
          <TextField
            fullWidth
            label="End Time"
            type="datetime-local"
            value={meetingData.endTime}
            onChange={(e) => setMeetingData({ ...meetingData, endTime: e.target.value })}
            InputLabelProps={{ shrink: true }}
            required
          />
          
          <TextField
            fullWidth
            label="Location"
            value={meetingData.location}
            onChange={(e) => setMeetingData({ ...meetingData, location: e.target.value })}
          />

          <FormControlLabel
            control={
              <Switch
                checked={meetingData.isOnline}
                onChange={(e) => setMeetingData({ ...meetingData, isOnline: e.target.checked })}
              />
            }
            label="Online Meeting"
          />
          
          <TextField
            fullWidth
            label="Description"
            multiline
            rows={4}
            value={meetingData.description}
            onChange={(e) => setMeetingData({ ...meetingData, description: e.target.value })}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleSubmit}
          variant="contained"
          disabled={!meetingData.title || !meetingData.startTime || !meetingData.endTime}
        >
          {isEdit ? 'Update Meeting' : 'Schedule Meeting'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewMeeting; 