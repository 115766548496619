import { create } from 'zustand';

const useTaskStore = create((set) => ({
  tasks: [],
  
  addTask: (taskData) => set((state) => ({
    tasks: [...state.tasks, { id: Date.now().toString(), ...taskData }]
  })),
  
  updateTask: (taskId, updates) => set((state) => ({
    tasks: state.tasks.map(task => 
      task.id === taskId ? { ...task, ...updates } : task
    )
  })),
  
  deleteTask: (taskId) => set((state) => ({
    tasks: state.tasks.filter(task => task.id !== taskId)
  })),

  reorderTasks: (startIndex, endIndex) => set((state) => {
    const newTasks = Array.from(state.tasks);
    const [removed] = newTasks.splice(startIndex, 1);
    newTasks.splice(endIndex, 0, removed);
    return { tasks: newTasks };
  })
}));

export default useTaskStore;
