import React from 'react';
import { Box, Typography, Chip, IconButton, Button } from '@mui/material';
import LightbulbOutlineIcon from '@mui/icons-material/LightbulbOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import TaskListLayout from '../components/Layouts/TaskListLayout';
import useTaskStore from '../store/useTaskStore';

const Tasks = () => {
  const tasks = useTaskStore(state => state.tasks);
  const pendingTasks = tasks.filter(task => task.status === 'pending');

  return (
    <Box>
      <Box 
        sx={{ 
          py: 3,
          px: 4,
          backgroundColor: 'background.paper',
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h5" fontWeight="600">
              Today's Focus
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={<TimerOutlinedIcon />}
          >
            Start Focus Session
          </Button>
        </Box>
        
        <Box display="flex" gap={2} alignItems="center">
          <Chip 
            label={`${pendingTasks.length} tasks pending`}
            color="primary" 
            variant="outlined"
          />
          <Box display="flex" alignItems="center" gap={1}>
            <LightbulbOutlineIcon color="action" fontSize="small" />
            <Typography variant="body2" color="text.secondary">
              Pro tip: Break down big tasks into smaller steps
            </Typography>
          </Box>
        </Box>
      </Box>
      <TaskListLayout />
    </Box>
  );
};

export default Tasks;

