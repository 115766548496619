import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
  IconButton,
} from '@mui/material';
import { format, isSameDay, addDays, subDays, startOfDay } from 'date-fns';
import useMeetingStore from '../../store/useMeetingStore';
import {
  Settings as SettingsIcon,
  ChevronLeft,
  ChevronRight,
  Today as TodayIcon,
} from '@mui/icons-material';
import TimelineConfig from '../Meeting/TimelineConfig';
import TimelineMeetingCard from '../Meeting/TimelineMeetingCard';
import NewMeeting from '../Meeting/NewMeeting';
import { useConfirm } from 'material-ui-confirm';
import MeetingViewDialog from '../Meeting/MeetingViewDialog';

const MeetingsTimelineLayout = () => {
  const theme = useTheme();
  const [view, setView] = useState('daily');
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const confirm = useConfirm();

  // Get store values
  const storedSelectedDate = useMeetingStore((state) => state.selectedDate);
  const setSelectedDate = useMeetingStore((state) => state.setSelectedDate);
  
  // Convert stored ISO string to Date object
  const selectedDate = new Date(storedSelectedDate);

  // Get meetings and config from store
  const meetings = useMeetingStore((state) => state.meetings);
  const timelineConfig = useMeetingStore((state) => state.timelineConfig);
  const updateTimelineConfig = useMeetingStore(
    (state) => state.updateTimelineConfig,
  );
  const deleteMeeting = useMeetingStore((state) => state.deleteMeeting);
  const updateMeeting = useMeetingStore((state) => state.updateMeeting);

  // Update current time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const handlePrevDay = () => {
    const newDate = subDays(selectedDate, 1);
    setSelectedDate(newDate);
  };

  const handleNextDay = () => {
    const newDate = addDays(selectedDate, 1);
    setSelectedDate(newDate);
  };

  const handleToday = () => {
    setSelectedDate(new Date());
  };

  const handleEdit = (meeting) => {
    setSelectedMeeting(meeting);
    setIsEditDialogOpen(true);
  };

  const handleDelete = async (meetingId) => {
    try {
      await confirm({
        title: 'Delete Meeting',
        description: 'Are you sure you want to delete this meeting?',
        confirmationText: 'Delete',
        confirmationButtonProps: { color: 'error' },
      });
      deleteMeeting(meetingId);
    } catch (error) {
      // User canceled the deletion
    }
  };

  const handleViewMeeting = (meeting) => {
    setSelectedMeeting(meeting);
    setIsViewDialogOpen(true);
  };

  const handleUpdateMeeting = (updatedData) => {
    if (selectedMeeting) {
      updateMeeting(selectedMeeting.id, {
        ...updatedData,
        id: selectedMeeting.id, // Ensure we keep the original ID
      });
      setIsEditDialogOpen(false);
      setSelectedMeeting(null);
    }
  };

  const DailyView = () => {
    const theme = useTheme();
    const timelineConfig = useMeetingStore((state) => state.timelineConfig);

    // Calculate minuteHeight based on the visible hours
    const totalMinutes =
      (timelineConfig.endHour - timelineConfig.startHour) * 60;
    const minuteHeight = 800 / totalMinutes;

    const selectedDayMeetings = meetings.filter((meeting) => {
      const meetingDate = new Date(meeting.startTime);
      return isSameDay(meetingDate, selectedDate);
    });

    const hoursToDisplay = Array.from(
      { length: timelineConfig.endHour - timelineConfig.startHour },
      (_, i) => i + timelineConfig.startHour,
    );

    return (
      <Box
        sx={{
          height: '800px',
          position: 'relative',
          mt: 2,
          backgroundColor: theme.palette.background.default,
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`,
          overflow: 'hidden',
        }}
      >
        {/* Hour lines */}
        <Box
          sx={{
            position: 'absolute',
            left: '60px',
            right: 0,
            top: 0,
            bottom: 0,
            backgroundImage: `linear-gradient(to bottom, ${theme.palette.divider} 1px, transparent 1px)`,
            backgroundSize: `100% ${60 * minuteHeight}px`,
            opacity: 0.2,
            pointerEvents: 'none',
          }}
        />

        {/* Time slots */}
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            width: '60px',
            borderRight: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.paper,
            zIndex: 1,
          }}
        >
          {hoursToDisplay.map((hour) => (
            <Box
              key={hour}
              sx={{
                height: `${60 * minuteHeight}px`,
                borderBottom: `1px solid ${theme.palette.divider}`,
                display: 'flex',
                alignItems: 'start',
                padding: '4px 8px',
                color: theme.palette.text.secondary,
              }}
            >
              <Typography variant="caption">
                {`${hour.toString().padStart(2, '0')}:00`}
              </Typography>
            </Box>
          ))}
        </Box>

        {/* Current time indicator */}
        <CurrentTimeLine
          minuteHeight={minuteHeight}
          startHour={timelineConfig.startHour}
          currentTime={currentTime}
          selectedDate={selectedDate}
        />

        {/* Meetings */}
        <Box
          sx={{
            marginLeft: '60px',
            position: 'relative',
            height: '100%',
            paddingRight: '8px',
          }}
        >
          {selectedDayMeetings.map((meeting) => {
            const startTime = new Date(meeting.startTime);
            const endTime = new Date(meeting.endTime);

            const startMinutes =
              (startTime.getHours() - timelineConfig.startHour) * 60 +
              startTime.getMinutes();

            const endMinutes =
              (endTime.getHours() - timelineConfig.startHour) * 60 +
              endTime.getMinutes();

            const top = startMinutes * minuteHeight;
            const height = (endMinutes - startMinutes) * minuteHeight;

            return (
              <TimelineMeetingCard
                key={meeting.id}
                meeting={meeting}
                top={top}
                height={height}
                onEdit={handleEdit}
                onDelete={handleDelete}
                onClick={() => handleViewMeeting(meeting)}
              />
            );
          })}
        </Box>
      </Box>
    );
  };

  // Separate CurrentTimeLine into its own component
  const CurrentTimeLine = ({
    minuteHeight,
    startHour,
    currentTime,
    selectedDate,
  }) => {
    const theme = useTheme();

    // Ensure both dates are Date objects for comparison
    const currentDate = new Date(currentTime);
    const selectedDateObj = new Date(selectedDate);

    // Only show if we're viewing today
    if (!isSameDay(currentDate, selectedDateObj)) {
      return null;
    }

    const minutes = (currentDate.getHours() - startHour) * 60 + currentDate.getMinutes();
    const top = minutes * minuteHeight;

    return (
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: `${top}px`,
          zIndex: 2,
          pointerEvents: 'none',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            left: '8px',
            transform: 'translateY(-50%)',
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
            padding: '2px 4px',
            borderRadius: 1,
            fontSize: '0.75rem',
          }}
        >
          {format(currentTime, 'HH:mm')}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            left: '60px',
            right: 0,
            height: '2px',
            backgroundColor: theme.palette.error.main,
            opacity: 0.7,
          }}
        />
      </Box>
    );
  };

  const handleSaveConfig = (newConfig) => {
    updateTimelineConfig(newConfig);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Paper
        sx={{
          p: 2,
          mb: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewChange}
            aria-label="view selector"
            size="small"
          >
            <ToggleButton value="daily" aria-label="daily view">
              Daily
            </ToggleButton>
            <ToggleButton value="weekly" aria-label="weekly view">
              Weekly
            </ToggleButton>
          </ToggleButtonGroup>

          <Box sx={{ display: 'flex', gap: 1 }}>
            <IconButton onClick={handlePrevDay} size="small">
              <ChevronLeft />
            </IconButton>
            <IconButton onClick={handleToday} size="small">
              <TodayIcon />
            </IconButton>
            <IconButton onClick={handleNextDay} size="small">
              <ChevronRight />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Typography variant="subtitle2" color="text.secondary">
            {format(selectedDate, 'EEEE, MMMM d')}
          </Typography>
          <IconButton size="small" onClick={() => setIsConfigOpen(true)}>
            <SettingsIcon />
          </IconButton>
        </Box>
      </Paper>

      <Paper
        sx={{
          overflow: 'hidden',
          position: 'relative',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        {view === 'daily' ? <DailyView /> : <WeeklyView />}
      </Paper>

      <TimelineConfig
        open={isConfigOpen}
        onClose={() => setIsConfigOpen(false)}
        config={timelineConfig}
        onSave={handleSaveConfig}
      />

      <NewMeeting
        open={isEditDialogOpen}
        onClose={() => {
          setIsEditDialogOpen(false);
          setSelectedMeeting(null);
        }}
        onAdd={handleUpdateMeeting}
        initialData={selectedMeeting}
        isEdit={true}
      />

      <MeetingViewDialog
        open={isViewDialogOpen}
        meeting={selectedMeeting}
        onClose={() => {
          setIsViewDialogOpen(false);
          setSelectedMeeting(null);
        }}
        onEdit={() => {
          setIsViewDialogOpen(false);
          handleEdit(selectedMeeting);
        }}
      />
    </Box>
  );
};

const WeeklyView = () => (
  <Box sx={{ p: 2 }}>
    <Typography variant="subtitle1" color="text.secondary">
      Weekly View (Coming Soon)
    </Typography>
  </Box>
);

export default MeetingsTimelineLayout;
