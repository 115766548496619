import React, { useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import useTaskStore from '../../store/useTaskStore';
import Task from '../Task/Task';
import TaskTableView from '../Task/TaskTableView';
import NewTaskInput from '../Task/NewTaskInput';
import TaskViewSelector from '../Task/TaskViewSelector';

const TaskListLayout = () => {
  const { tasks, addTask, updateTask, deleteTask, reorderTasks } = useTaskStore();
  const [currentView, setCurrentView] = useState('list');

  const handleAddTask = (taskData) => {
    addTask({
      title: taskData.title,
      status: 'pending',
      priority: 'medium',
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    reorderTasks(source.index, destination.index);
  };

  return (
    <Box 
      sx={{ 
        maxWidth: '100%',
        px: { xs: 2, sm: 3 },
        py: { xs: 2, sm: 4 },
        backgroundColor: 'background.default',
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          maxWidth: 1400,
          mx: 'auto',
        }}
      >
        {/* Header Section */}
        <Paper 
          elevation={0} 
          sx={{ 
            p: 3, 
            mb: 3, 
            backgroundColor: 'background.paper',
            borderRadius: 2,
          }}
        >
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              mb: 3,
              gap: 2,
              flexWrap: 'wrap'
            }}
          >
            <Typography variant="h5" component="h1" sx={{ fontWeight: 500 }}>
              Tasks
            </Typography>
            <TaskViewSelector
              currentView={currentView}
              onViewChange={setCurrentView}
            />
          </Box>
          <NewTaskInput onAddTask={handleAddTask} />
        </Paper>

        {/* Tasks View */}
        {currentView === 'list' ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="taskList">
              {(provided) => (
                <Box
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  sx={{ minHeight: 100 }}
                >
                  {tasks.map((task, index) => (
                    <Draggable key={task.id} draggableId={String(task.id)} index={index}>
                      {(provided, snapshot) => (
                        <Task
                          task={task}
                          index={index}
                          onEdit={updateTask}
                          onDelete={deleteTask}
                          provided={provided}
                          snapshot={snapshot}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <TaskTableView
            tasks={tasks}
            onEdit={updateTask}
            onDelete={deleteTask}
          />
        )}
      </Box>
    </Box>
  );
};

export default TaskListLayout;
